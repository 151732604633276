import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'contato',
    loadChildren: () => import('./contato/contato.module').then( m => m.ContatoPageModule)
  },
  {
    path: 'quemsomos',
    loadChildren: () => import('./quemsomos/quemsomos.module').then( m => m.QuemsomosPageModule)
  },
  {
    path: 'avaliacoes',
    loadChildren: () => import('./avaliacoes/avaliacoes.module').then( m => m.AvaliacoesPageModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog.module').then( m => m.BlogPageModule)
  },
  {
    path: 'post/:id',
    loadChildren: () => import('./post/post.module').then( m => m.PostPageModule)
  },
  {
    path: 'post',
    loadChildren: () => import('./post/post.module').then( m => m.PostPageModule)
  },
  {
    path: 'viagens',
    loadChildren: () => import('./viagens/viagens.module').then( m => m.ViagensPageModule)
  },
  {
    path: 'viagens/:continente/:ano/:tema',
    loadChildren: () => import('./viagens/viagens.module').then( m => m.ViagensPageModule)
  },
  {
    path: 'viagens/:continente',
    loadChildren: () => import('./viagens/viagens.module').then( m => m.ViagensPageModule)
  },
  {
    path: 'viagem/:id',
    loadChildren: () => import('./viagem/viagem.module').then( m => m.ViagemPageModule)
  },
  {
    path: 'viagem',
    loadChildren: () => import('./viagem/viagem.module').then( m => m.ViagemPageModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then( m => m.AdminPageModule)
  },  {
    path: 'obrigado',
    loadChildren: () => import('./obrigado/obrigado.module').then( m => m.ObrigadoPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
