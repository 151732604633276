import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  
  whatsappFab = "";
  rota = "";

  constructor(
    public db: AngularFirestore,
    public router: Router
  ) {
    router.events.forEach((event) => {
      if(event instanceof NavigationStart) {
        this.rota = event.url
      }
    });
  }

  ngOnInit() {
    this.db.collection('contato').doc('contato').ref.get().then(doc => {
      this.whatsappFab = doc.data().whatsappFab
    })
  }
}
