export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBC9t9fbXZufr3oEiqfhOBKjxbqqSmvUss",
    authDomain: "dmitry-543bd.firebaseapp.com",
    projectId: "dmitry-543bd",
    storageBucket: "dmitry-543bd.appspot.com",
    messagingSenderId: "1060657830191",
    appId: "1:1060657830191:web:154e812448f49da38da9da"
  }
};